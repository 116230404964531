














































































































































































































































































































































































































































































































































.safeItem {
  .el-form-item__content {
    display: flex;
    align-items: flex-end;
    .img-el-upload {
      min-width: 275px;
    }
  }
}
